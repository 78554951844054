import React from 'react';
import { useState, useEffect } from 'react';
import { getStorageUnits } from '../../../api/client';

const SelectUnit = ({ updateData, onConfirm }) => {
    const [selectedUnit, setSelectedUnit] = useState(-1);
    const [products, setProducts] = useState([]);

    useEffect(() => { updateData({ selectedUnit: undefined }) }, []);

    const handleUnitSelect = (unit) => {
        setSelectedUnit(unit);
        console.log(products[unit]);
        updateData({ selectedUnit: products[unit] });
    };

    useEffect(() => {
        getStorageUnits().then((p) => {
            p.sort((a, b) => a.size - b.size);
            setProducts(p);
            console.log(p);
        })
    }, []);

    const productsList = products.map((prod, idx) =>
        <div
            key={prod.id}
            className={`unit ${selectedUnit === idx ? 'selected-unit' : ''}`}
            onClick={() => handleUnitSelect(idx)}
        >
            <h2>{prod.size / 100}m<sup>2</sup></h2>
        </div>
    )

    return (
        <div>
            <div className='section'>
                <h1>Select your unit</h1>
                <div className='onboard-form'>
                    <div className='scroll-menu'>
                        <div className='unit-select'>
                            {productsList}
                        </div>
                    </div>
                    {selectedUnit >= 0 ? ( // Render selected unit data if a unit is selected
                        <div className='selected-storage-unit-container casper-box-shadow'>
                            <div className='selected-storage-unit'>
                                <div className='storage-video'>
                                    <img src={products[selectedUnit].image} alt='storage-unit'></img>
                                </div>
                                <div className='storage-description'>
                                    <div>
                                        <h3>{products[selectedUnit].name}</h3>
                                        <h4>1.5m x 1.5m</h4>
                                        <p>{products[selectedUnit].description}</p>
                                    </div>
                                    <div className='price-tag'>
                                        <h1>${products[selectedUnit].price / 100}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <h1>No unit selected</h1>
                        </div>
                    )};
                    <div className='helpful-tip'>
                        <p>Helpful Tip: Once we pickup your items we can update your storage plan if you have more or less items.</p>
                    </div>
                    <div className='form-btn-container'>
                        <button className='casper-btn casper-btn-blue' onClick={onConfirm}>Confirm</button>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default SelectUnit;
