
import CasperHero from '../assets/casper-hero.svg';
import CasperShapes from '../assets/caspershapes.svg';
import CasperHeroImg from '../assets/movingguysboxes.png';
import CasperHeroImg2 from '../assets/hero-image.png';



import OnboardButton from './GetAQoute';

const Hero = () => {
    return (
        <>
            <div className='hero-banner'>

            <div className='hero-content'>
                        <div className='hero-header'>
                            <h1 >Smarter storage for busy kiwis</h1>
                        </div>
                        <div className='hero-paragraph '>
                            <p>We take all the work out of storage while saving you money.</p>
                        </div>
                        <button className='button button-primary'>Get Storage</button>

                </div>

                <div className='hero-image'>
                    <img src={CasperHeroImg2} />
                </div>

            </div>

        </>
    )
}

export default Hero;