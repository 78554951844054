import PageTitle from '../components/PageTitle';
import HowItWorks from '../components/HowItWorks';
import PricingComparison from '../components/PricingComparison';
const Pricing = () => {
    return (
        <div>


            <div className='section'> 
            <PageTitle page_title='Pricing' />
            <PricingComparison />
            <p>We guarantee the cheapest storage in town.</p>
            <br />
            <p>Our on-demand storage service eliminates the need for you to visit a storage facility. We'll come directly to your location, pick up your belongings, and transport them to our secure storage facility. When you need your items back, simply let us know, and we'll deliver them right to your doorstep. </p>
            <br />
            <p>Whether you're moving, downsizing, or just need more space, our professional team will handle all the logistics for you. Our focus is on providing a seamless and stress-free storage experience that saves you time, effort and money.</p>
            <HowItWorks />
                
            </div>

        </div>
    );
};
 
export default Pricing;