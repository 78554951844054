import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import CasperLogoBlue from "../assets/CasperLogoBlue.svg";
import CasperLogoWhite from "../assets/CasperLogoWhite.svg";
import { Form, Button } from "react-bootstrap";

const NavbarCasper = () => {
  const [navbarBg, setNavbarBg] = useState("dark");
  const [navbarLogo, setNavbarLogo] = useState(false);
  const [navbarMode, setNavbarMode] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Adjust the scroll threshold and background colors according to your requirements
      //   if (scrollTop > 0) {
      //     setNavbarBg('dark');
      //     setNavbarLogo(false);
      //     setNavbarMode(false);
      //   } else {
      //     setNavbarBg('light');
      //     setNavbarLogo(true);
      //     setNavbarMode(true);

      //   }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar bg={navbarBg} expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={`${navbarLogo ? CasperLogoBlue : CasperLogoWhite}`}
              height="100"
              className={`d-inline-block align-top ${navbarLogo}`}
              alt="Casper Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className={`ms-auto ${
                navbarMode ? `casper-light` : `casper-dark`
              }`}
            >
              <NavDropdown
                title="Storage"
                id="basic-nav-dropdown"
                className={`${navbarMode ? `casper-light` : `casper-dark`}`}
              >
                <NavDropdown.Item href="/personal-storage">
                  Personal Storage
                </NavDropdown.Item>
                <NavDropdown.Item href="/student-storage">
                  Student Storage
                </NavDropdown.Item>
                <NavDropdown.Item href="/student-storage">
                  Life Events
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/commercial-storage">Commercial Storage</Nav.Link>
              <Nav.Link href="#link">How Casper works</Nav.Link>
              <Nav.Link href="/FAQ">FAQs</Nav.Link>
            </Nav>
            <Form className="d-flex">
              <button
                className={`casper-btn navbar-btn ${
                  navbarMode ? `casper-btn-blue` : `casper-btn-white`
                }`}
              >
                Login
              </button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarCasper;
