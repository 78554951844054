const OnboardAction = ({ onBook, onQuote }) => {
    return (
        <div className='onboard-action'>


            <div className='button-section'>

                <div className='onboard-action-button' onClick={onBook}>
                    <button className='casper-btn casper-btn-blue'>Book Appointment</button>
                </div>
                <div className='onboard-action-button' onClick={onQuote}>
                    <button className='casper-btn casper-btn-secondary'>Email Qoute</button>
                </div>
            </div>
        </div>

    );
};

export default OnboardAction;