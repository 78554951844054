import React, { useEffect, useState, } from 'react';
import GooglePlacesAutocomplete from 'react-google-autocomplete';
import AutocompleteInput from './AutoCompleteInput';

const AddressSection = ({ updateData, onConfirm }) => {
  const [placeIsValid, setPlaceIsValid] = useState("unknown");

  useEffect(() => updateData({ address: undefined }), []);

  const handlePlaceSelect = (place) => {
    if (!place) {
      return;
    } else if (!place.geometry) {
      setPlaceIsValid("unknown");
    } else {
      console.log(place);
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      console.log('Selected Place:', place.geometry.location.lat());

      // Define the boundaries of the desired area
      const areaBounds = {
        north: -36.6776628020347, // Latitude of northern boundary
        south: -36.833919923289365, // Latitude of southern boundary
        east: 174.8210837093741, // Longitude of eastern boundary
        west: 174.6628527703827, // Longitude of western boundary
      };

      // Check if the coordinates are within the desired area
      const isWithinArea =
        lat >= areaBounds.south &&
        lat <= areaBounds.north &&
        lng >= areaBounds.west &&
        lng <= areaBounds.east;
      console.log(isWithinArea);

      setPlaceIsValid(isWithinArea ? "inrange" : "outofrange");
      if (isWithinArea) {
        console.log("Selected Place", place);
        updateData({ address: place.formatted_address });
      } else {
        updateData({ address: undefined });
      }
    }

  };

  return (
    <div>
      <div className='section'>
        <h1>Enter your address</h1>
        <div className='onboard-form'>
          <div className='form-section '>
            <div className={placeIsValid ? 'valid-input' : 'invalid-input'}>
              <AutocompleteInput
                id='address'
                name='address'
                onPlaceSelected={handlePlaceSelect}
                onChange={() => { setPlaceIsValid("unknown"); updateData({ address: undefined }) }}
                placeIsValid={placeIsValid}
              />
              {placeIsValid === "notinrange" && (
                <div className='address-not-valid'>
                  <p>Sorry, we're not in your area yet.</p>
                </div>
              )}
            </div>
          </div>
          <div className='form-btn-container'>
            <button className='casper-btn casper-btn-blue' onClick={onConfirm}>
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressSection;
