import PickupImage from '../assets/pickup_image.svg';
import CatalogueImage from '../assets/catalogue_image.svg';
import StoredImage from '../assets/storeit_image.svg';
import DeliveredImage from '../assets/delivered_image.svg';
import PageTitle from './PageTitle';


const HowItWorks = () => {
    return (

        <div className='section'>
            <h1>How it works</h1>
            <div className='steps-container'>
                <div className='step drop-shadow'>
                    <span class='step-circle'>
                        <h1>1</h1>
                    </span>                
                    <img className='step-pickup-img' src={PickupImage}></img>
                    <h3>We pickup your stuff</h3>
                    <p>Once you've booked an appointment, our team will arrive during the pick up window.</p>
                    <br />
                    <p>If you need our team for longer than your allocated time, it's just $79 per 30 minutes.</p>
                </div>
                <div className='step drop-shadow'>
                    <span class='step-circle'>
                        <h1>2</h1>
                    </span>                
                    <img src={CatalogueImage}></img>
                    <h3>Catalogue it</h3>
                    <p>We'll make your items available through your online portal. </p>
                    <br />
                    <p>You'll recieve an email to activate your account within 12 hours of us picking up your stuff.</p>
                </div>
                <div className='step drop-shadow'>
                    <span class='step-circle'>
                        <h1>3</h1>
                    </span>                
                    <img src={StoredImage}></img>
                    <h3>Store it in our warehouse</h3>
                    <p>We'll take your items to one of our secure storage facilities. </p>
                </div>
                <div className='step drop-shadow'>
                    <span class='step-circle'>
                        <h1>4</h1>
                    </span>
                    <img src={DeliveredImage}></img>
                    <h3>When you want it, we'll deliver</h3>
                    <p>If you want any or all of your items back, simply book the return through your online portal.</p>
                </div>
            </div>
        </div>

    )
}

export default HowItWorks;