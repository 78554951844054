import React, { useEffect } from 'react';
import { useState } from 'react';

const StorageTerm = ({ onConfirm, updateData }) => {
    const [termIdx, setTermIdx] = useState(-1);

    useEffect(() => {
        if (termIdx === -1) {
            updateData({ term: undefined });
        } else {
            console.log(termIdx);
            let { term, stripeId: stripeTerm } = terms[termIdx];
            updateData({ term, stripeTerm });
        }
    }, [termIdx]);

    const handleConfirm = () => {
        if (termIdx >= 0) {
            onConfirm()
        } else {
            console.log('No storage length selected');
        }
    }

    const terms = [
        { title: "Month to Month", desc: "Flexible cancel anytime", term: 1, stripeId: null },
        { title: "3 Month Term", desc: "Save 10% on storage", term: 3, stripeId: '3-month-term' },
        { title: "6 Month Term", desc: "Save 15% on storage and free pickup", term: 6, stripeId: '6-month-term' },
    ];

    const termList = terms.map(({ title, desc, term }, idx) => {
        return (
            <div
                className={`casper-option-card ${termIdx === idx ? 'casper-option-card-selected' : ''}`}
                onClick={() => setTermIdx(idx)}
            >
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>);
    });

    return (
        <div>
            <div className='section'>
                <h1>Select your term</h1>
                <div className='onboard-form'>
                    <div className='casper-option-select-container'>
                        {termList}
                    </div>

                    <div className='form-btn-container'>
                        <button className='casper-btn casper-btn-blue' onClick={handleConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default StorageTerm;
