import CasperLogo from '../assets/CasperLogo.png';
import checkMarkIcon from '../assets/check-mark.svg'

const PricingComparison = ({}) => {
    return (

        <div className='section'>
            <h1>Convenience is key</h1>

            <div class='comparison-table'>
                <div className='table-container'>
                    <table>
                        <th></th>
                        <th><img src={CasperLogo} style={{width: '100px'}}/></th>
                        <th><h3>Smartbox</h3></th>
                        <th><h3>Self-Storage</h3></th>

                        <tr>
                            <td className='row-head'>Secure Storage</td>
                            <td><img src={checkMarkIcon} /></td>
                            <td><img src={checkMarkIcon} /></td>
                            <td><img src={checkMarkIcon} /></td>
                        </tr>
                        <tr>
                            <td className='row-head'>Pickup and Delivery</td>
                            <td><img src={checkMarkIcon} /></td>
                            <td><img src={checkMarkIcon} /></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className='row-head'>Packing Help</td>
                            <td><img src={checkMarkIcon} /></td>
                            <td><img src={checkMarkIcon} /></td>
                            <td></td>
                        </tr>
                        
                        <tr>
                            <td className='row-head'>Online Catalogue of items</td>
                            <td><img src={checkMarkIcon} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        
                        <tr>
                            <td className='row-head'>Withdraw individual items</td>
                            <td><img src={checkMarkIcon} /></td>
                            <td></td>
                            <td></td>
                        </tr>
                        

                    </table>
                </div>
            </div>


        </div>

    )
}

export default PricingComparison;