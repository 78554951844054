import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

export default function AutocompleteInput({ onPlaceSelected, placeIsValid, validity, ...props }) {
  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: "AIzaSyDyDmqSat_SlHWnDwUfjEzywx2rRZVTC9I",
    onPlaceSelected,
    options: {
      types: ["address"],
      componentRestrictions: { country: 'nz' },
    },
  });

  let inputClass = '';
  if (placeIsValid === "inrange") {
    inputClass = 'is-valid';
  } else if (placeIsValid === "outofrange") {
    inputClass = 'is-invalid';
  }

  return (
    <div>
      <label htmlFor='address'>Pickup Address</label>
      <div className='addressinputcontainer' id='address'>
        <input
          className={`form-control form-control-lg ${inputClass}`}
          ref={ref}
          type="text"
          {...props}
        ></input>
      </div>
    </div>
  )

  // return (
  //   <div>
  //     <label htmlFor='address'>Pickup Address</label>
  //     <div className='addressinputcontainer' id='address'>
  //       <GooglePlacesAutocomplete
  //         className={`form-control form-control-lg ${placeIsValid ? 'is-valid' : 'is-invalid'}`}
  //         apiKey="AIzaSyDyDmqSat_SlHWnDwUfjEzywx2rRZVTC9I"
  //         onPlaceSelected={onPlaceSelected}
  //         options={{
  //           types: ['address'],
  //           componentRestrictions: { country: 'nz' }
  //         }}
  //         onChange={() => console.log("A")}
  //       />
  //     </div>
  //   </div>
  // );
};
