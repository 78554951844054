import React, { useEffect } from 'react';
import { useState } from 'react';

const UserDetails = ({ onConfirm, updateData }) => {
    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [phone, setPhone] = useState(undefined);

    useEffect(() => {
        if (firstName && lastName && email && phone) {
            let details = { firstName, lastName, email, phone };
            updateData({ details })
        } else {
            updateData({ details: undefined })
        }
    }, [firstName, lastName, email, phone]);

    return (
        <div>
            <div className='section'>
                <h1>Pickup Details</h1>
                <div className='onboard-form'>
                    <div className='form-content'>
                        <div className='input-element'>
                            <label htmlFor="fname">First name:</label>
                            <input type="text" id="fname" name="fname" onChange={(ev) => setFirstName(ev.target.value)} />
                        </div>
                        <div className='input-element'>
                            <label htmlFor="lname">Last name:</label>
                            <input type="text" id="lname" name="lname" onChange={(ev) => setLastName(ev.target.value)} />
                        </div>
                        <div className='input-element'>
                            <label htmlFor="email">Email:</label>
                            <input type="text" id="email" name="email" onChange={(ev) => setEmail(ev.target.value)} />
                        </div>
                        <div className='input-element'>
                            <label htmlFor="phone">Phone Number:</label>
                            <input type="text" id="phone" name="phone" onChange={(ev) => setPhone(ev.target.value)} />
                        </div>
                    </div>

                    <div className='form-btn-container'>
                        <button className='casper-btn casper-btn-blue' onClick={onConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDetails;
