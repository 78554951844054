import React from 'react';
import Hero from '../components/Hero'
import OurServices from '../components/OurServices';
import WhyChooseUs from '../components/WhyChooseUs';
import HowItWorks from '../components/HowItWorks';
import ServiceComparison from '../components/ServiceComparison';
import GetAQoute from '../components/GetAQoute';
import WhatWeOffer from '../components/WhatWeOffer';
const Home = () => {
    return (
        <div>
            <Hero />
            <WhatWeOffer />
            <GetAQoute />

            <WhyChooseUs
            title='Why store with Casper'
            paragraph='Never visit a self-storage unit again. Casper stores your things in our secure warehouse, when you want some or all of your items back simply request through our portal and we will deliver. ' />

            <HowItWorks />

            <GetAQoute />

            <ServiceComparison/>

            
        
        </div>
    );
};
 
export default Home;