import React, { useEffect } from 'react';
import { useState } from 'react';
import Calendar from './Calendar';

const DatePicker = ({ updateData, onConfirm }) => {
  const [date, setDate] = useState(undefined);
  useEffect(() => { updateData({ date }) }, [date]);

  function handleSelect(dateString, dateObj) {
    let date = `${dateObj.selectedYear}-${dateObj.selectedMonth}-${dateObj.selectedDay}`;
    setDate({ year: dateObj.selectedYear, month: dateObj.selectedMonth, day: dateObj.selectedDay });
  }

  return (
    <div className='section'>
      <div className='onboard-form'>
        <h1>Select pickup day</h1>
        <Calendar onSelect={handleSelect} />
        <div className='form-btn-container'>
          <button className='casper-btn casper-btn-blue' onClick={onConfirm}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
