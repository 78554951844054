const SERVER_URL = process.env.REACT_APP_SERVER_URL || "https://api.meetcasper.co.nz";

export async function getStorageUnits() {
    let resp = await fetch(`${SERVER_URL}/bookings/storage`, {
        method: 'GET'
    });

    if (resp.ok) {
        return resp.json();
    } else {
        throw Error();
    }
}

/**
 * 
 * @param {string} address A single line address
 * @param {StripeID} stripeProduct The stripe id of the chosen product
 * @param {number} term The storage term in months
 * @param {StripeID} stripeTermDiscount The stripe ID of the term discount coupon
 * @param {{ firstName: string, lastName: string, email: string, phone: string }} details An object containing the name, email, and phone of the user
 * @param {Date} pickupTime
 * @param {number} window The number of minutes long that the pickup window is
 * @param {StripeID} stripePickup The stripe price id of the chosen pickup window
 * @param {boolean} sendQuote Whether or not to send a PDF quote 
 * @returns {boolean}
 */
export async function createBooking(
    address,
    stripeProduct,
    term,
    stripeTermDiscount,
    details,
    pickupTime,
    stripePickup,
    window,
    sendQuote,
) {
    let body = {
        address,
        term,
        ...details,
        pickupTime,
        window,
        sendQuote,
        stripeProduct,
        stripeTermDiscount,
        stripePickup,
    };

    let resp = await fetch(`${SERVER_URL}/bookings`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    if (!resp.ok) {
        console.error(await resp.text());
    }

    return resp.ok;
}