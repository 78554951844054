import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/themes/base/all.css';

export default function MyDatePicker({ onSelect }) {
  const inputRef = useRef(null);

  useEffect(() => {
    $(inputRef.current).datepicker({
      create: () => console.log("Hello, world!"),
      minDate: 2,
      firstDay: 1,
      beforeShowDay: function (date) {
        // Specify the weekdays to block (0 = Sunday, 1 = Monday, etc.)
        var blockedWeekdays = [1, 2, 3]; // Sunday and Saturday

        // Check if the day's index is in the blockedWeekdays array
        var day = date.getDay();
        var isBlocked = (blockedWeekdays.indexOf(day) !== -1);

        // Return the result as an array [isSelectable, cssClass]
        return [!isBlocked, ''];
      },
      onSelect,
    });
  }, []);

  return (
    <div className="question-section">
      <div ref={inputRef}></div>
    </div>
  );
}
