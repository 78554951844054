import React, { useEffect } from 'react';
import { useState } from 'react';

const TimeSlot = ({ onConfirm, updateData }) => {
    const [slotIdx, setSlotIdx] = useState(-1);

    const options = [
        { hour: 8, minute: 0, window: 540, text: "8am - 5pm", price: 0, stripeId: "price_1NJrFgCoLE9lU6tZLyYGILHU" },
        { hour: 8, minute: 0, window: 120, text: "8am - 10am", price: 59, stripeId: "price_1NJrFgCoLE9lU6tZLyYGILHU" },
        { hour: 10, minute: 0, window: 120, text: "10am - 12am", price: 59, stripeId: "price_1NJrFgCoLE9lU6tZLyYGILHU" },
        { hour: 13, minute: 0, window: 120, text: "1pm - 3pm", price: 59, stripeId: "price_1NJrFgCoLE9lU6tZLyYGILHU" },
        { hour: 15, minute: 0, window: 120, text: "3pm - 5pm", price: 59, stripeId: "price_1NJrFgCoLE9lU6tZLyYGILHU" },
    ];

    useEffect(() => {
        if (slotIdx !== -1) {
            updateData({ timeslot: options[slotIdx], stripeTimeslot: options[slotIdx].stripeId });
        } else {
            updateData({ timeslot: undefined, stripeTimeslot: undefined });
        }
    }, [slotIdx]);

    const listOptions = options.map((el, idx) =>
        <div
            className={`casper-option-card ${slotIdx === idx ? 'casper-option-card-selected' : ''}`}
            onClick={() => setSlotIdx(idx)}
            key={el.text}
        >
            <h3>{el.text}</h3>
            <h2 className='price'>{el.price === 0 ? 'Free' : "$" + el.price}</h2>
        </div>
    );

    return (
        <div>
            <div className='section'>
                <h1>Select Timeslot</h1>
                <div className='onboard-form'>
                    <div className='casper-option-select-container'>
                        {listOptions}
                    </div>

                    <div className='form-btn-container'>
                        <button className='casper-btn casper-btn-blue' onClick={onConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TimeSlot;
