import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarCasper from './components/Navbar.js';
import Home from './pages/index';
import PersonalStorage from './pages/PersonalStorage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import StudentStorage from './pages/StudentStorage';
import Footer from './components/Footer';
import CommercialStorage from './pages/CommercialStorage';
import Pricing from './pages/Pricing';
import LoadingContainer from './components/LoaderContainer';
import {useState, useEffect} from 'react';
import Onboarding from './pages/onboarding/Onboarding';
import FAQ from './pages/FAQs';
import Hotjar from '@hotjar/browser';

const siteId = 3600744;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      setLoading(true);
      setTimeout(() => {
          setLoading(false);
      }, 1000);
  }, []);

  
  return (
    <>
      {loading === false ? (
        <Router>
          <div className='page-content'>
              <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/personal-storage' element={<PersonalStorage />}  />
              <Route path='/student-storage' element={<StudentStorage />} />
              <Route path='/commercial-storage' element={<CommercialStorage />} />
              <Route path='/pricing' element={<Pricing /> } />
              <Route path='/FAQ' element={<FAQ />} />
              {/* onboard page */}
              <Route path='/onboard' element={<Onboarding />} />
            </Routes>
          </div>
          <Footer />
          <NavbarCasper />
        </Router>
      ) : (
        <LoadingContainer />
      )}
      </>
  );
}

export default App;


