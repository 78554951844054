
import CasperHero from '../assets/casper-hero.svg';
import CasperShapes from '../assets/caspershapes.svg';
import CasperHeroImg from '../assets/movingguysboxes.png';
import CasperHeroImg2 from '../assets/hero-image.png';



import OnboardButton from './GetAQoute';

const CtaHero = ({cta_text, cta_image}) => {
    return (
        <>
            <div className='cta-hero-banner'>

                <div className='cta-hero-text'>
                    <h1>Plans starting at just <span className='price'>$89</span> per month</h1>
                </div>

                <div className='cta-hero-button'>
                    <button className='button button-primary'>Get Storage</button>
                </div>

            </div>

        </>
    )
}

export default CtaHero;