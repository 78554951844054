


const WhyChooseUs = ({title, paragraph}) => {
    return (
        <div className='section'>

            <div class='casper-block drop-shadow'>
                <h3>{title}</h3>
                <p>{paragraph}</p>
                {/* <h3>Why store with Casper</h3> */}
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pretium tincidunt magna sit amet eleifend. Sed fermentum hendrerit metus eget sodales. Vestibulum pretium sagittis purus molestie porta. Integer vestibulum porttitor nibh, vitae placerat nisi sodales in. </p> */}

            </div>

        </div>
    )
}

export default WhyChooseUs;