

import CasperHero from '../assets/casper-hero.svg';
import CasperShapes from '../assets/caspershapes.svg';
import { Container, Col } from 'react-bootstrap';
import { Facebook } from 'react-bootstrap-icons';
import CasperLogoBlue from '../assets/CasperLogoBlue.svg'

const Footer = () => {
    return (
        <Container>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <Col md={4} className="d-flex align-items-center">
            <a href="/" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                <img src={CasperLogoBlue} />
            </a>
            <span className="mb-3 mb-md-0 text-muted">&copy; 2023</span>
          </Col>
  
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3">
              <a className="text-muted" href="#">
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted" href="#">
              </a>
            </li>
            <li className="ms-3">
              <a className="text-muted" href="#">
              </a>
            </li>
          </ul>
        </footer>
      </Container>


    )
}

export default Footer;