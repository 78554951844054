

const WhatWeOffer = () => {
    return (
        <div className='section'>
        <h1>What we offer</h1>
        <div className='whatweoffer-cards'>
                <div className='whatweoffer-card'>
                    <h2>Storage</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <a href='/personal-storage'>
                        <button className='button button-secondary'>Find out more</button>

                    </a>
                </div>

                <div className='whatweoffer-card'>
                    <h2>Student Storage</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <a href='/student-storage'>
                        <button className='button button-secondary'>Find out more</button>

                    </a>                </div>
                <div className='whatweoffer-card'>
                    <h2>Business Storage</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    <a href='/business-storage'>
                        <button className='button button-secondary'>Find out more</button>

                    </a>                </div>

        </div>

        </div>
    )
}

export default WhatWeOffer;