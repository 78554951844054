import PersonalStorageImage from '../assets/personalstorage.jpeg';


const PageTitle = ({page_title}) => {
    return (

            <div className='page-title-container'>
                <div className='page-title'>
                        {/* <img className='page-title-img' src={PersonalStorageImage} /> */}
                        <div className='page-title-text'>
                            <h1>{page_title}</h1>

                        </div>
                </div>
            </div>

    )
}

export default PageTitle;