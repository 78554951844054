import BusinessStorage from '../assets/business-storage.jpeg';
import PersonalStorage from '../assets/personalstorage.jpeg';
import StudentStorage from '../assets/studentstorage.jpeg';

const GetAQoute = () => {
    return (
        <div className='section'>
            <div class='CTA-btn'>
                <a href='/onboard'>
                    <button class='casper-btn casper-btn-blue'>Get a qoute</button>
                </a>
            </div>

        </div>
    )
}

export default GetAQoute;