import AddressSection from "./components/AddressSection";
import DatePicker from "./components/DatePicker";
import SelectStorage from "./components/SelectUnit";
import StorageTerm from "./components/StorageTerm";
import TimeSlot from "./components/Timeslot";
import UserDetails from "./components/UserDetails";
import OnboardAction from "./components/OnboardAction";
import { useState } from "react";
import { createBooking } from "../../api/client";

const Onboarding = () => {
    const [currentStep, setCurrentStep] = useState(1);
    const [formState, setFormState] = useState({});

    function finalizeBooking(sendQuote) {
        return () => {
            let { address, selectedUnit, term, stripeTerm, details, date, timeslot, stripeTimeslot } = formState;
            console.log(date, timeslot);
            let pickupTime = new Date(date.year, date.month, date.day, timeslot.hour, timeslot.minute);
            createBooking(address, selectedUnit.price_id, term, stripeTerm, details, pickupTime, stripeTimeslot, timeslot.window, sendQuote);
        }
    }

    function nextStep(thisStep) {
        return () => {
            console.log('Selected step:', thisStep);
            setCurrentStep(thisStep + 1);
        }
    }

    function updateFormState(newState) {
        let currentData = formState;
        Object.assign(currentData, newState);
        setFormState(currentData);
        console.log(currentData);
    }

    return (
        <div className='onboarding-main'>
            <div className={`step-container ${currentStep >= 1 ? '' : ' component-disabled'}`}>
                <AddressSection
                    onConfirm={nextStep(1)}
                    updateData={updateFormState}
                />
            </div>
            <div className={`step-container ${currentStep >= 2 ? '' : ' component-disabled'}`}>
                <SelectStorage
                    onConfirm={nextStep(2)}
                    updateData={updateFormState}
                />
            </div>
            <div className={`step-container ${currentStep >= 3 ? '' : ' component-disabled'}`}>
                <StorageTerm
                    onConfirm={nextStep(3)}
                    updateData={updateFormState}
                />
            </div>
            <div className={`step-container ${currentStep >= 4 ? '' : ' component-disabled'}`}>
                <UserDetails
                    onConfirm={nextStep(4)}
                    updateData={updateFormState}
                />

            </div>
            <div className={`step-container ${currentStep >= 5 ? '' : ' component-disabled'}`}>
                <DatePicker
                    onConfirm={nextStep(5)}
                    updateData={updateFormState}
                />

            </div>
            <div className={`step-container ${currentStep >= 6 ? '' : ' component-disabled'}`}>
                <TimeSlot
                    onConfirm={nextStep(6)}
                    updateData={updateFormState}
                />
                <p style={{ textAlign: 'center' }}>
                    {formState.selectedUnit && formState.discount
                        ? "$" + (formState.selectedUnit.price * formState.discount).toFixed(2)
                        : "No Options Selected"}
                </p>
            </div>
            <div className={`step-container ${currentStep >= 7 ? '' : ' component-disabled'}`}>
                <OnboardAction
                    onBook={finalizeBooking(false)}
                    onQuote={finalizeBooking(true)}
                />
            </div>


        </div>
    );
};

export default Onboarding;