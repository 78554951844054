import { useEffect } from "react";
import { useState } from "react";
import LoadingGif from '../assets/loading-gif.gif';
import CasperLogo from '../assets/CasperLogoBlue.svg';

const LoaderContainer = () => {


    return (

        <div className='loadingcontainer'>
            <div>
                <img className='loading-animation' src={LoadingGif}></img>    
                <img className='logo' src={CasperLogo}></img>
            </div>
        </div>

    )
}

export default LoaderContainer;