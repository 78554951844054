import PageTitle from "../components/PageTitle";
import HowItWorks from "../components/HowItWorks";
import { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FAQ = () => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <PageTitle page_title="FAQs" />
      <div className="section">
        <div className='desktop-content'>
          <p>Have a look at our Frequently Asked Questions, if these don't answer your queries get in touch.</p>
        <Accordion open={open} toggle={toggle}>
          <AccordionItem>
            <AccordionHeader targetId="1">
              What can't I store with Casper?
            </AccordionHeader>
            <AccordionBody accordionId="1">
              {/* <strong>This is the first item&#39;s accordion body.</strong> */}
              <ul>
                <li>
                  Flammable Liquids: Items such as gasoline, kerosene, or
                  propane tanks that can easily catch fire.
                </li>
                <li>
                  Living Creatures: Pets, animals, or any living organisms that
                  require care, feeding, or specific environmental conditions.
                </li>

                <li>
                  Firearms and Ammunition: Guns, firearms, or any type of
                  weapons, along with their corresponding ammunition.
                </li>
              </ul>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">
              What if I need more space?
            </AccordionHeader>
            <AccordionBody accordionId="2">
              If your storage requirements change, whether you need more or less
              space than originally booked, we are flexible and will accommodate
              your needs by adjusting your plan accordingly. Should you find
              that you require additional space after adding an item, we will
              promptly update your plan to ensure you have the necessary storage
              capacity.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">Accordion Item 3</AccordionHeader>
            <AccordionBody accordionId="3">
              <strong>This is the third item&#39;s accordion body.</strong>
              You can modify any of this with custom CSS or overriding our
              default variables. It&#39;s also worth noting that just about any
              HTML can go within the <code>.accordion-body</code>, though the
              transition does limit overflow.
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </div>
      </div>
    </div>
  );
};

export default FAQ;
